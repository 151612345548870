import { useLocalStorage } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';
import { useLatest } from 'react-use';

import { formatToLocale } from '@eluve/date-utils';
import { EluveMutationObserver } from '@eluve/eluve-mutation-observer';
import { doMajorAndMinorVersionsMatch } from '@eluve/utils';

/**
 * If making any breaking changes to the extension, update minor version
 * Patch does not matter, extension only considers major and minor
 *
 * It would be great if we could get this from manifest.json file instead
 */
export const extensionVersionRequired = '0.5.0';

/**
 * Hook to detect whether the Eluve extension exists on the page.
 *
 * The extension injects a div with ID 'eluveExtExists' into the page to
 * indicate that it is present.
 *
 * We use a MutationObserver to detect when the div is added to the page
 * since the div is injected asynchronously and it may not be present
 * when the component first renders.
 *
 * @returns {boolean} Whether the Eluve extension exists on the page
 */

type EluveExtData = {
  lastChecked?: string;
  extensionVersion?: string;
  exists?: boolean;
};

export const useEluveExtExists = () => {
  const [eluveExtExists, setEluveExtExists] = useState<boolean | undefined>(
    undefined,
  );
  const [eluveExtData, setEluveExtData] = useLocalStorage<EluveExtData>(
    'ELUVE_EXTENSION',
    {},
  );
  const [eluveExtVersionSatisfies, setEluveExtVersionSatisfies] = useState<
    boolean | undefined
  >(undefined);
  const extExistsRef = useLatest(eluveExtExists);

  useEffect(() => {
    const checkEluveExt = () => {
      const eluveExt = document.getElementById('eluveExtExists');
      if (eluveExt) {
        setEluveExtVersionSatisfies(
          doMajorAndMinorVersionsMatch(
            eluveExt.innerText,
            extensionVersionRequired,
          ),
        );
        setEluveExtExists(true);
        setEluveExtData({
          lastChecked: formatToLocale(new Date().toISOString()),
          extensionVersion: eluveExt.innerText,
          exists: true,
        });
        return true;
      }
      return false;
    };

    if (!checkEluveExt()) {
      const observer = new EluveMutationObserver(async () => {
        if (checkEluveExt()) {
          observer.disconnect();
        }
      }, 100);

      // Start observing the document body for added nodes
      observer.start();

      // Clean up the observer on component unmount
      return () => observer.disconnect();
    }
  }, [setEluveExtData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Use the latest state from the ref
      if (extExistsRef.current === undefined) {
        setEluveExtExists(false);
        setEluveExtData({
          lastChecked: formatToLocale(new Date().toISOString()),
          extensionVersion: undefined,
          exists: false,
        });
      }
    }, 3000);

    return () => clearTimeout(timer); // Clean up the timeout on unmount or state change
  }, [setEluveExtData, extExistsRef]);

  return {
    eluveExtExists: eluveExtData?.exists ?? eluveExtData,
    eluveExtVersionSatisfies,
  };
};
