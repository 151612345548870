import { useMutation, useSuspenseQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Cross1Icon } from '@radix-ui/react-icons';
import { Trash } from 'lucide-react';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { z } from 'zod';

import { cacheUtils } from '@eluve/apollo-client';
import { TooltipLabel } from '@eluve/blocks';
import {
  Box,
  Button,
  Card,
  Combobox,
  ComboboxDropdown,
  ComboboxOption,
  ComboboxSelectButton,
  ComboboxSelectCheck,
  Dialog,
  DialogContent,
  EluveCardContent,
  EluveCardHeader,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  H2,
  Input,
  P,
  PageTitle,
} from '@eluve/components';
import { LocationBillingCodes } from '@eluve/frontend-feature-appointment';
import {
  LocationUsers,
  externalEhrsFragment,
  getLocationPageLayoutQuery,
  insertNewExternalEhrMutation,
  locationUsersFragment,
  tenantUsersFragment,
  updateLocationDetailsMutation,
} from '@eluve/frontend-feature-location-hierarchy';
import { VendorTypesLookup } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';
import {
  FeatureFlaggedComponent,
  useUploadGenericFile,
} from '@eluve/smart-blocks';

const newEhrFormSchema = z.object({
  vendor: z.nativeEnum(VendorTypesLookup),
  domain: z
    .string()
    .min(1)
    .transform((val) => {
      // remove leading protocol and www and trailing slashes
      const domain = val
        .replace(/^(https?:\/\/)?(www\.)?/i, '')
        .replace(/\/$/, '');

      return domain;
    }),
});

const locationDetailsFormSchema = z.object({
  logo: z.instanceof(File).optional(),
  isLogoUpdated: z.boolean().default(false),
  address: z.string().optional(),
  phoneNumber: z.string().optional(),
  externalEhrId: z.string().optional(),
  externalId: z.string().optional(),
});

type NewEhrForm = z.infer<typeof newEhrFormSchema>;
type LocationDetailsForm = z.infer<typeof locationDetailsFormSchema>;

const locationPageQuery = graphql(
  `
    query GetLocationPage($tenantId: uuid!, $locationId: String!) {
      tenantsByPk(id: $tenantId) {
        __typename
        id
        ...TenantUsers
      }
      locationsByPk(tenantId: $tenantId, id: $locationId) {
        __typename
        id
        ...LocationUsers
      }
    }
  `,
  [locationUsersFragment, tenantUsersFragment],
);

export const LocationPage: React.FC = () => {
  const { tenantId, locationId } = useParams() as {
    tenantId: string;
    locationId: string;
  };

  const newEhrForm = useForm<NewEhrForm>({
    resolver: zodResolver(newEhrFormSchema),
    defaultValues: {
      vendor: undefined,
      domain: '',
    },
  });

  const { data } = useSuspenseQuery(getLocationPageLayoutQuery, {
    variables: {
      locationId,
      tenantId,
    },
  });

  useSuspenseQuery(locationPageQuery, {
    variables: {
      tenantId,
      locationId,
    },
  });

  const { uploadGenericFileToGCS } = useUploadGenericFile(tenantId);

  const location = data.locationsByPk;
  const tenantExternalEhrs = data?.tenantsByPk?.externalEhrs ?? [];

  const locationDetailsFormDefaultValues: Partial<
    LocationDetailsForm & { logoUrl: string }
  > = {
    logoUrl: location?.logoUrl ?? '',
    isLogoUpdated: false,
    phoneNumber: location?.phoneNumber ?? '',
    address: location?.address ?? '',
    externalEhrId: location?.externalEhrId ?? '',
    externalId: location?.externalId ?? '',
  };

  const locationDetailsForm = useForm<LocationDetailsForm>({
    resolver: zodResolver(locationDetailsFormSchema),
    defaultValues: locationDetailsFormDefaultValues,
    values: {
      externalEhrId: location?.externalEhrId ?? '',
      isLogoUpdated: false,
      address: location?.address ?? '',
      externalId: location?.externalId ?? '',
    },
  });

  const logoFile = locationDetailsForm.watch('logo');

  const logoFileObjectUrl = useMemo(() => {
    if (logoFile) {
      return URL.createObjectURL(logoFile);
    }
    return '';
  }, [logoFile]);

  const [updateLocationDetails] = useMutation(updateLocationDetailsMutation, {
    onCompleted: () => {
      toast.success('Location Details Updated');
    },
    onError: () => {
      toast.error('Failed to update location details');
    },
  });

  const [insertExternalEhr] = useMutation(insertNewExternalEhrMutation, {
    onCompleted: () => toast.success('External EHR created'),
    optimisticResponse: ({ input }) => ({
      insertExternalEhrOne: {
        __typename: 'ExternalEhr' as const,
        id: 'optimistic-id',
        vendor: input.vendor!,
        domain: input.domain!,
      },
    }),
    update: (_cache, { data }) => {
      cacheUtils.updateFragment(
        {
          fragment: externalEhrsFragment,
          key: { id: tenantId },
        },
        (existing) => {
          if (!existing || !data?.insertExternalEhrOne) {
            return existing;
          }
          return {
            __typename: 'Tenants' as const,
            id: tenantId,
            externalEhrs: [
              ...(existing.externalEhrs || []),
              data.insertExternalEhrOne,
            ],
          };
        },
      );
    },
  });

  const [createNewEhrFormOpen, setCreateNewEhrFormOpen] =
    useState<boolean>(false);

  const onSubmitNewEhr = async (data: NewEhrForm) => {
    try {
      const result = await insertExternalEhr({
        variables: {
          input: {
            vendor: data.vendor,
            domain: data.domain,
          },
        },
      });

      const newExternalEhrId = result.data?.insertExternalEhrOne?.id;

      if (newExternalEhrId) {
        await updateLocationDetails({
          variables: {
            locationId,
            tenantId,
            externalEhrId: newExternalEhrId,
          },
        });
      }

      newEhrForm.reset({
        vendor: undefined,
        domain: '',
      });
      setCreateNewEhrFormOpen(false);
    } catch (e) {
      toast.error('Failed to create external EHR.');
    }
  };

  const onSubmitLocationDetails = async (data: LocationDetailsForm) => {
    try {
      let logoUrl = location?.logoUrl ?? '';
      if (data.isLogoUpdated) {
        const { logo } = data;
        if (!logo) {
          logoUrl = '';
        } else {
          const fileExt = logo.name.split('.').pop();
          if (!fileExt) {
            toast.error('Invalid file extension.');
            return;
          }
          const result = await uploadGenericFileToGCS({
            file: logo,
            mimeType: 'image/*',
            fileExt,
          });
          if (result.url) {
            logoUrl = result.url;
          }
        }
      }

      await updateLocationDetails({
        variables: {
          locationId,
          tenantId,
          logoUrl,
          address: data.address ?? '',
          phoneNumber: data.phoneNumber ?? '',
          // We don't want to pass an empty string here since its for a foreign key
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          externalEhrId: data.externalEhrId || null,
          externalId: data.externalId ?? null,
        },
      });
    } catch {
      toast.error('Failed to update location details.');
    }
  };

  if (!location?.id) {
    return <Navigate to="/not-found" />;
  }

  return (
    <div className="container pb-11">
      <div className="grid gap-5">
        <PageTitle>{location?.name}</PageTitle>
      </div>

      <Dialog
        open={createNewEhrFormOpen}
        onOpenChange={setCreateNewEhrFormOpen}
      >
        <DialogContent className="flex flex-col items-center justify-center gap-5 p-10">
          <H2>Create new EHR</H2>
          <Form {...newEhrForm}>
            <form
              className="mx-auto mt-5 flex max-w-md flex-col items-start gap-x-4"
              onSubmit={newEhrForm.handleSubmit(onSubmitNewEhr)}
            >
              <FormField
                control={newEhrForm.control}
                name="vendor"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="w-80">
                    <label htmlFor="vendor-name" className="sr-only">
                      Vendor
                    </label>
                    <div className="mr-2 w-80 rounded-lg border bg-white">
                      <Combobox>
                        <ComboboxSelectButton className="w-full border-transparent hover:bg-gray-3">
                          <Box
                            hStack
                            className="overflow-hidden text-ellipsis whitespace-nowrap text-sm"
                          >
                            {field.value ?? 'Select Vendor'}
                          </Box>
                        </ComboboxSelectButton>
                        <ComboboxDropdown searchPlaceholder="Select Vendor">
                          {Object.keys(VendorTypesLookup).map((vendor) => (
                            <ComboboxOption
                              key={vendor}
                              onSelect={() => field.onChange(vendor)}
                            >
                              <ComboboxSelectCheck
                                className="mr-2"
                                selected={field.value === vendor}
                              />
                              {vendor}
                            </ComboboxOption>
                          ))}
                        </ComboboxDropdown>
                      </Combobox>
                    </div>
                    {error && (
                      <FormMessage className="mt-4">
                        {error.message}
                      </FormMessage>
                    )}
                  </FormItem>
                )}
              />
              <FormField
                control={newEhrForm.control}
                name="domain"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="w-80">
                    <label htmlFor="domain-name" className="sr-only">
                      Domain
                    </label>
                    <Input
                      className="bg-white/5"
                      placeholder="Enter Domain"
                      {...field}
                    />
                    {error && (
                      <FormMessage className="mt-4">
                        {error.message}
                      </FormMessage>
                    )}
                  </FormItem>
                )}
              />
              <Button className="mt-2">Add new EHR</Button>
            </form>
          </Form>

          <div className="flex w-full items-center justify-center gap-3">
            <Button
              type="button"
              className="border border-gray-5 bg-white text-gray-10 hover:bg-white"
              onClick={() => setCreateNewEhrFormOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <div className="grid gap-5">
        <Card className="w-full rounded-xl border-gray-4">
          <EluveCardHeader>
            <div>
              <P>Location Details</P>
            </div>
          </EluveCardHeader>
          <EluveCardContent>
            <Form {...locationDetailsForm}>
              <form
                className="flex max-w-md flex-col gap-x-4 gap-y-4"
                onSubmit={locationDetailsForm.handleSubmit(
                  onSubmitLocationDetails,
                )}
              >
                <FormField
                  control={locationDetailsForm.control}
                  name="externalEhrId"
                  render={({ field, fieldState: { error } }) => (
                    <FormItem className="flex flex-row">
                      <label htmlFor="External EHR" className="sr-only">
                        External EHR
                      </label>
                      {Boolean(tenantExternalEhrs.length) && (
                        <div className="flex flex-row">
                          <div className="flex w-28 self-center">
                            <P>Select EHR:</P>
                          </div>
                          <div className="mr-2 w-80 rounded-lg border bg-white">
                            <Combobox>
                              <ComboboxSelectButton className="w-full border-transparent hover:bg-gray-3">
                                <Box
                                  hStack
                                  className="overflow-hidden text-ellipsis whitespace-nowrap text-sm"
                                >
                                  {(() => {
                                    const selectEhr = tenantExternalEhrs.find(
                                      (ehr) => ehr.id === field.value,
                                    );
                                    return selectEhr
                                      ? `${selectEhr.vendor} - ${selectEhr.domain}`
                                      : 'Select EHR';
                                  })()}
                                </Box>
                              </ComboboxSelectButton>
                              <ComboboxDropdown searchPlaceholder="Select EHR">
                                {tenantExternalEhrs.map((ehr) => (
                                  <ComboboxOption
                                    key={ehr.id}
                                    onSelect={() => field.onChange(ehr.id)}
                                  >
                                    <ComboboxSelectCheck
                                      className="mr-2"
                                      selected={field.value === ehr.id}
                                    />
                                    {`${ehr.vendor} - ${ehr.domain}`}
                                  </ComboboxOption>
                                ))}
                              </ComboboxDropdown>
                            </Combobox>
                          </div>
                          <div className="mr-2 flex w-5 self-center">
                            <TooltipLabel label="Remove EHR from location">
                              <Button
                                size="icon"
                                variant="ghost"
                                className="hover:bg-transparent hover:text-brand-9"
                                type="button"
                                onClick={() => {
                                  field.onChange('');
                                }}
                              >
                                <Cross1Icon />
                              </Button>
                            </TooltipLabel>
                          </div>
                          <div className="mr-3 flex self-center">
                            <P className="italic">OR</P>
                          </div>
                        </div>
                      )}
                      <div className="flex self-center">
                        <Button
                          type="button"
                          variant="default"
                          onClick={() => setCreateNewEhrFormOpen(true)}
                        >
                          Create New EHR
                        </Button>
                      </div>
                      {error && (
                        <FormMessage className="mt-4">
                          {error.message}
                        </FormMessage>
                      )}
                    </FormItem>
                  )}
                />
                <FormField
                  control={locationDetailsForm.control}
                  name="logo"
                  render={({ field, fieldState: { error } }) => (
                    <FormItem>
                      <label htmlFor="logo-url" className="sr-only">
                        Logo
                      </label>
                      <FormLabel>Logo</FormLabel>
                      {Boolean(location.logoUrl && !logoFileObjectUrl) && (
                        <img
                          src={location.logoUrl!}
                          alt="logo"
                          className="max-h-28"
                        />
                      )}

                      {logoFileObjectUrl && (
                        <Box hStack className="mb-2 items-start">
                          <img
                            src={logoFileObjectUrl}
                            alt="logo"
                            className="max-h-14"
                          />
                          <Button
                            variant="outline"
                            size="smallIcon"
                            onClick={() => field.onChange(undefined)}
                          >
                            <Trash />
                          </Button>
                        </Box>
                      )}
                      <FormControl>
                        <Input
                          type="file"
                          className="w-72 rounded-lg border border-brandGray300 bg-white"
                          onChange={async (e) => {
                            if (e.currentTarget.files?.length) {
                              const currentFile = e.currentTarget.files[0];
                              field.onChange(currentFile);
                              locationDetailsForm.setValue(
                                'isLogoUpdated',
                                true,
                              );
                            }
                          }}
                        />
                      </FormControl>
                      <FormDescription>
                        This logo will be automatically inherited by descendants
                        of this location if they don't have one
                      </FormDescription>
                      {error && (
                        <FormMessage className="mt-4">
                          {error.message}
                        </FormMessage>
                      )}
                    </FormItem>
                  )}
                />
                <FormField
                  control={locationDetailsForm.control}
                  name="address"
                  render={({ field, fieldState: { error } }) => (
                    <FormItem>
                      <FormLabel>Address</FormLabel>
                      <FormControl>
                        <Input placeholder="Address" {...field} />
                      </FormControl>
                      {error && (
                        <FormMessage className="mt-4">
                          {error.message}
                        </FormMessage>
                      )}
                    </FormItem>
                  )}
                />

                <FormField
                  control={locationDetailsForm.control}
                  name="phoneNumber"
                  render={({ field, fieldState: { error } }) => (
                    <FormItem>
                      <FormLabel>Phone</FormLabel>
                      <FormControl>
                        <Input placeholder="Phone Number" {...field} />
                      </FormControl>
                      {error && (
                        <FormMessage className="mt-4">
                          {error.message}
                        </FormMessage>
                      )}
                    </FormItem>
                  )}
                />
                <FormField
                  control={locationDetailsForm.control}
                  name="externalId"
                  render={({ field, fieldState: { error } }) => (
                    <FormItem>
                      <FormLabel>External Id</FormLabel>
                      <FormControl>
                        <Input placeholder="External Id" {...field} />
                      </FormControl>
                      <FormDescription>
                        A unique identifier for the location in an external
                        system
                      </FormDescription>
                      {error && (
                        <FormMessage className="mt-4">
                          {error.message}
                        </FormMessage>
                      )}
                    </FormItem>
                  )}
                />
                <Button
                  className="w-fit"
                  disabled={!locationDetailsForm.formState.isDirty}
                >
                  Save
                </Button>
              </form>
            </Form>
          </EluveCardContent>
        </Card>
        <Card className="w-full">
          <EluveCardHeader>
            <P>Users</P>
          </EluveCardHeader>
          <EluveCardContent>
            <LocationUsers locationId={locationId} tenantId={tenantId} />
          </EluveCardContent>
        </Card>
        <FeatureFlaggedComponent flag="BILLING_CODE_PRICES">
          <Card className="w-full">
            <EluveCardHeader>
              <P>Billing Codes</P>
            </EluveCardHeader>
            <EluveCardContent>
              <LocationBillingCodes
                locationId={locationId}
                tenantId={tenantId}
              />
            </EluveCardContent>
          </Card>
        </FeatureFlaggedComponent>
      </div>
    </div>
  );
};
