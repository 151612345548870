import { CheckCircle2Icon, Loader2 } from 'lucide-react';
import React, { useState } from 'react';
import { toast } from 'sonner';

import {
  Button,
  Dialog,
  DialogContent,
  H2,
  Input,
  NewButton,
  P,
  Switch,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useToast,
} from '@eluve/components';
import {
  useAppointmentId,
  useAppointmentPatient,
  useExternalAppointmentId,
  useSignNoteOnEluve,
  useSyncNoteToEhr,
} from '@eluve/frontend-appointment-hooks';
import { useNamedLogger } from '@eluve/logger';
import { useCaptureEvent } from '@eluve/posthog-react';
import { useTenantIdFromParams } from '@eluve/session-helpers';
import { useEluveExtExists } from '@eluve/smart-blocks';
import { formatHumanName, toTitleCase } from '@eluve/utils';
import { HumanFriendlyError, getVendorProvider } from '@eluve/vendor';

import { messageClient } from '../../../../utils/post-messenger';

export const SyncToEhrButton: React.FC = () => {
  const logger = useNamedLogger('SignAndSyncAppointmentButton');
  const [userProvidedChartUrl, setUserProvidedChartUrl] = useState<
    string | null
  >(null);
  const [manualChartUrlEntry, setManualChartUrlEntry] = useState(false);

  const { toast: toaster } = useToast();
  const syncNoteToEhr = useSyncNoteToEhr({ messageClient });
  const signNoteOnEluve = useSignNoteOnEluve();

  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    useState<boolean>(false);
  const [signNoteDialogOpen, setSignNoteDialogOpen] = useState<boolean>(false);
  const [signingInProgress, setSigningInProgress] = useState<boolean>(false);
  const [signingEhrError, setSigningEhrError] =
    useState<HumanFriendlyError | null>(null);
  const [shouldSignExternalChart, setShouldSignExternalChart] = useState(false);

  const tenantId = useTenantIdFromParams() ?? '';

  const appointmentId = useAppointmentId();
  const appointmentExternalId = useExternalAppointmentId();

  const captureEvent = useCaptureEvent({
    tenantId,
    appointmentId,
    externalAppointmentId: appointmentExternalId,
  });

  const patient = useAppointmentPatient();
  const patientId = patient?.id;
  const patientFullName = formatHumanName(
    patient?.firstName ?? '',
    patient?.lastName,
  );

  const externalPatientInfo = patient?.external_patients_info?.[0];
  const externalEhr = externalPatientInfo?.external_ehr;
  const ehrName = toTitleCase(externalEhr?.vendor ?? '');
  const ehrVendorProvider = externalEhr?.vendor
    ? getVendorProvider(externalEhr.vendor)
    : null;
  const ehrVendorSyncConfig = ehrVendorProvider?.getSyncConfig();

  const patientExternalId = externalPatientInfo?.externalPatientId;
  const patientEhrUrl = ehrVendorProvider?.getPatientEhrUrl({
    domain: externalEhr?.domain ?? '',
    externalPatientId: patientExternalId,
  });
  const { eluveExtExists } = useEluveExtExists();

  const showSuccessToast = (text: string, signed: boolean) => {
    toaster({
      duration: 4 * 1000, // 4 seconds
      variant: 'success',

      title: (
        <div className="flex w-full items-center">
          <CheckCircle2Icon className="mr-2 h-10 w-10" />
          <div className="flex flex-col gap-0.5">
            <span className="text-base font-semibold first-letter:capitalize">
              {signed
                ? 'Note Signed Successfully'
                : 'Note Drafted Successfully'}
            </span>
            <span className="font-normal">{text}</span>
          </div>
        </div>
      ),
    });
  };

  const onButtonClick = async () => {
    // Open the dialog with the confirmation content
    setConfirmationDialogOpen(true);
  };

  const signNoteOnEluveWithUserProvidedChartUrl = async (): Promise<void> => {
    if (!userProvidedChartUrl) {
      return;
    }
    const chartId =
      ehrVendorProvider?.getChartIdFromChartUrl?.(userProvidedChartUrl);
    if (!chartId) {
      return;
    }

    setConfirmationDialogOpen(false);
    setSignNoteDialogOpen(false);
    setSigningEhrError(null);
    const isSubmitted = await signNoteOnEluve({
      chartUrl: userProvidedChartUrl,
      chartId,
    });

    if (isSubmitted) {
      captureEvent('signed_note_on_eluve_only_with_user_provided_chart');
      showSuccessToast(`Your note has been synced.`, shouldSignExternalChart);
    } else {
      captureEvent(
        'failed_to_sign_note_on_eluve_only_with_user_provided_chart',
      );
      toast.error('Failed to sign note.');
    }
  };

  const syncToExternalEHRAndSignOnEluve = async (): Promise<void> => {
    setConfirmationDialogOpen(false);
    setSigningEhrError(null);
    setSignNoteDialogOpen(true);
    setSigningInProgress(true);

    if (!externalEhr) {
      logger.warn(
        `Attempted to sign note for appointment ${appointmentId} without external EHR`,
      );
      return;
    }

    const response = await syncNoteToEhr({
      shouldSignExternalChart,
    });

    logger.info('Sync to note EHR response', response);

    if (!response.ok || !response.data) {
      logger.warn('SyncToEhr response was not successful', {
        response,
      });
      if (response?.humanFriendlyError) {
        setSigningEhrError(response.humanFriendlyError);
      }
      return;
    }

    setSignNoteDialogOpen(false);

    const successSigningOnEluve = await signNoteOnEluve({
      chartUrl: response.data.chartUrl,
      chartId: response.data.chartId,
    });

    if (successSigningOnEluve) {
      captureEvent('signed_note_and_synced_to_external_ehr');
      showSuccessToast(
        `Your note has been successfully synced to ${ehrName}.`,
        shouldSignExternalChart,
      );
    } else {
      captureEvent('failed_to_sign_note_and_sync_to_external_ehr');
      toast.error(
        `Your note has been successfully synced to ${ehrName}, but we experienced an error signing the note on Eluve.`,
      );
    }
  };

  const canSyncNoteToEhr = Boolean(
    ehrVendorSyncConfig?.canSyncNoteToEhr({
      externalPatientId: patientExternalId,
      externalAppointmentId: appointmentExternalId,
    }),
  );

  const externalSyncNoteAvailable = eluveExtExists && canSyncNoteToEhr;

  const externalSignNoteAvailable =
    eluveExtExists && ehrVendorSyncConfig?.canSignNoteInEhr;

  return (
    <div className="w-full">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <NewButton
              disabled={!externalSyncNoteAvailable}
              onClick={onButtonClick}
              text="Sync to EHR"
              wFull
            />
          </TooltipTrigger>
          {!externalSyncNoteAvailable && (
            <TooltipContent>
              {!patientId && 'Select a patient before signing the note'}
            </TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>

      <Dialog
        open={confirmationDialogOpen}
        onOpenChange={setConfirmationDialogOpen}
      >
        <DialogContent className="flex flex-col items-center justify-center gap-5 p-10">
          <H2>Are you sure?</H2>
          {externalSignNoteAvailable && (
            <div className="flex items-center gap-2">
              <Switch
                checked={shouldSignExternalChart}
                onCheckedChange={setShouldSignExternalChart}
              />
              <P className="text-center text-base font-medium text-gray-10">
                Sign external chart
              </P>
            </div>
          )}

          <P className="text-center text-base font-medium text-contentSecondary">
            The contents of this note will be exported to {ehrName}{' '}
            {externalSignNoteAvailable && (
              <span>
                {' '}
                as a{' '}
                <span className="font-black text-black">
                  {shouldSignExternalChart ? 'signed note' : 'draft'}
                </span>
              </span>
            )}{' '}
            for patient{' '}
            <a
              className="text-contentPrimary"
              href={patientEhrUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {patientFullName}
            </a>
            .
          </P>

          <P className="text-center text-base font-medium text-gray-10">
            You will no longer be able to edit this note in Eluve.
          </P>

          <div className="flex w-full items-center justify-center gap-3">
            <Button
              className="border border-gray-5 bg-white text-gray-10 hover:bg-white"
              onClick={() => setConfirmationDialogOpen(false)}
            >
              Cancel
            </Button>
            <NewButton
              type="primary"
              onClick={syncToExternalEHRAndSignOnEluve}
              text={
                shouldSignExternalChart
                  ? 'Yes, Sync and Sign Note'
                  : 'Yes, Sync Note as Draft'
              }
            />
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={signNoteDialogOpen} onOpenChange={setSignNoteDialogOpen}>
        <DialogContent className="flex flex-col items-center justify-center gap-5 p-10">
          {signingInProgress && (
            <div>
              <div className="flex flex-col items-center">
                <H2>Syncing to {ehrName}</H2>
              </div>
              <P className="m-3 text-center text-base font-medium text-gray-10">
                We are in the process of syncing to {ehrName}. This should only
                take a few moments.
              </P>
              <div className="flex items-center justify-center">
                <Loader2 className="m-8 h-12 w-12 animate-spin text-teal" />
              </div>
            </div>
          )}

          {signingEhrError && (
            <div>
              {manualChartUrlEntry ? (
                <div>
                  <div className="m-3 flex flex-col items-center">
                    <H2>Add Chart URL</H2>

                    <Input
                      className="m-5"
                      type="text"
                      placeholder="Chart URL"
                      onChange={(e) => setUserProvidedChartUrl(e.target.value)}
                    />
                    <div className="flex w-full items-center justify-center gap-3">
                      <Button
                        className="border border-gray-5 bg-white text-gray-10 hover:bg-white"
                        onClick={() => setManualChartUrlEntry(false)}
                      >
                        Cancel
                      </Button>
                      <NewButton
                        type="primary"
                        text="Add Chart URL and Sign"
                        disabled={
                          !userProvidedChartUrl ||
                          !userProvidedChartUrl.startsWith('http') ||
                          !ehrVendorProvider?.getChartIdFromChartUrl?.(
                            userProvidedChartUrl,
                          )
                        }
                        onClick={async () => {
                          await signNoteOnEluveWithUserProvidedChartUrl();
                          setSigningEhrError(null);
                          setSigningInProgress(false);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="m-3 flex flex-col items-center">
                    <H2>{signingEhrError.title}</H2>
                  </div>
                  <div className="mb-6">
                    <P className="text-center text-base font-medium text-gray-10">
                      {signingEhrError.message}
                    </P>
                  </div>
                  <div className="flex w-full items-center justify-center gap-3">
                    <Button
                      className="border border-gray-5 bg-white text-gray-10 hover:bg-white"
                      onClick={() => setSignNoteDialogOpen(false)}
                    >
                      Cancel
                    </Button>

                    {ehrVendorSyncConfig?.canSyncWithManualChartUrl &&
                      ehrVendorProvider?.getChartIdFromChartUrl && (
                        <Button
                          className="border border-gray-5 bg-white text-gray-10 hover:bg-white"
                          onClick={() => {
                            setManualChartUrlEntry(true);
                          }}
                        >
                          Manually Add Chart URL
                        </Button>
                      )}
                    <NewButton
                      type="primary"
                      text="Try Again"
                      onClick={syncToExternalEHRAndSignOnEluve}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
