import { DynamicArtifactTemplate } from '@eluve/llm-outputs';
import { AppointmentSummaryKeys } from '@eluve/utils';

import { AppointmentModel } from '../../models/appointment';
import { ArtifactType } from '../../models/artifact';
import { ChartType } from '../../models/chart';
import { ChartTemplateType } from '../../models/chart-template';
import { PatientType } from '../../models/patient';
import {
  GetEhrDataOptions,
  PatientEhrUrlArgs,
  Vendor,
  VendorSyncConfig,
} from '../vendor';

export class MockVendor implements Vendor {
  private mockAppointments: AppointmentModel[];
  private mockPatient: PatientType | null;
  private mockLogo: string;
  private mockHomeUrl: string;
  private mockChart: ChartType;
  private mockChartIdFromUrl: string | null;
  private mockArtifact: ArtifactType | null;
  private mockChartTemplate: ChartTemplateType | null;
  private mockDynamicArtifactTemplate: DynamicArtifactTemplate | null;
  private mockChartTemplateUrl: string;
  private mockSyncConfig: VendorSyncConfig;

  constructor(args?: {
    appointments?: AppointmentModel[];
    patient?: PatientType | null;
    logo?: string;
    homeUrl?: string;
    chart?: ChartType;
    chartIdFromUrl?: string | null;
    artifact?: ArtifactType | null;
    chartTemplate?: ChartTemplateType | null;
    dynamicArtifactTemplate?: DynamicArtifactTemplate | null;
    chartTemplateUrl?: string;
    syncConfig?: VendorSyncConfig;
  }) {
    this.mockAppointments = args?.appointments ?? [];
    this.mockPatient = args?.patient ?? null;
    this.mockLogo = args?.logo ?? 'data:image/png;base64,MOCK_LOGO';
    this.mockHomeUrl = args?.homeUrl ?? 'https://mockvendorhome.com';

    this.mockChart = args?.chart ?? {
      externalPatientId: 'MOCK_PATIENT_ID',
      externalChartId: 'MOCK_CHART_ID',
      externalAppointmentId: null,
      signedAt: null,
      rawData: {},
      chartUrl: 'https://mockvendor.com/chart',
    };

    this.mockChartIdFromUrl = args?.chartIdFromUrl ?? 'MOCK_CHART_ID';
    this.mockArtifact = args?.artifact ?? null;
    this.mockChartTemplate = args?.chartTemplate ?? null;
    this.mockDynamicArtifactTemplate = args?.dynamicArtifactTemplate ?? null;
    this.mockChartTemplateUrl =
      args?.chartTemplateUrl ?? 'https://mockvendor.com/chart-template';

    this.mockSyncConfig = args?.syncConfig ?? {
      canSignNoteInEhr: true,
      canSyncNoteToEhr: () => true,
      canSyncWithManualChartUrl: true,
      syncStartDate: undefined,
      syncEndDate: undefined,
    };
  }

  getAppointments(
    data: unknown,
    options?: GetEhrDataOptions,
  ): AppointmentModel[] {
    return this.mockAppointments;
  }

  getPatient(data: unknown, options?: GetEhrDataOptions): PatientType | null {
    return this.mockPatient;
  }

  getPatientEhrUrl(data: PatientEhrUrlArgs): string {
    return `https://mockvendor.com/ehr-url/${data.externalPatientId}`;
  }

  getLogo(): string {
    return this.mockLogo;
  }

  getHomeUrl(domain: string): string {
    return `${this.mockHomeUrl}/${domain}`;
  }

  getChart(
    data: unknown,
    additionalFields?: Record<string, unknown>,
  ): ChartType {
    return {
      ...this.mockChart,
      rawData: {
        ...this.mockChart.rawData,
        ...additionalFields,
      },
    };
  }

  getChartIdFromChartUrl?(chartUrl: string): string | null {
    return this.mockChartIdFromUrl;
  }

  convertChartToSummary?(data: unknown): {
    [key in AppointmentSummaryKeys]?: string;
  } {
    return {
      SUBJECTIVE: 'Mock Summary 1',
      OBJECTIVE: 'Mock Summary 2',
      ASSESSMENT: 'Mock Summary 3',
      PLAN: 'Mock Summary 4',
    };
  }

  convertChartToParts?(data: unknown): Record<string, unknown>[] {
    return [{ part1: 'Mock Part 1' }, { part2: 'Mock Part 2' }];
  }

  getArtifact?(data: unknown): ArtifactType | null {
    return this.mockArtifact;
  }

  getChartTemplate?(data: unknown): ChartTemplateType | null {
    return this.mockChartTemplate;
  }

  convertChartTemplateToDynamicArtifactTemplate?(
    data: ChartTemplateType,
  ): DynamicArtifactTemplate | null {
    return this.mockDynamicArtifactTemplate;
  }

  getChartTemplateUrl?(data: {
    domain: string;
    externalChartTemplateOwnerId: string;
    externalChartTemplateId: string;
  }): string {
    return `${this.mockChartTemplateUrl}/${data.externalChartTemplateId}`;
  }

  getSyncConfig(): VendorSyncConfig {
    return this.mockSyncConfig;
  }
}
