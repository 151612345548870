import { useSuspenseQuery } from '@apollo/client';
import React, { Suspense } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { H3, HStack, LinkButton, PageTitle } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { TabNav } from '@eluve/smart-blocks';

const appointmentNameQuery = graphql(`
  query appointmentName($tenantId: uuid!, $appointmentId: uuid!) {
    appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
      __typename
      id
      name
    }
  }
`);

export const AdminAppointmentOutlet: React.FC = () => {
  const { tenantId, appointmentId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };

  const {
    data: { appointmentsByPk: appointment },
  } = useSuspenseQuery(appointmentNameQuery, {
    variables: { tenantId, appointmentId },
  });

  const datadogUrl = `https://us5.datadoghq.com/logs?query=%40appointmentId%3A${appointmentId}`;
  const inAppUrl = `/tenants/${tenantId}/appointments/${appointmentId}?tab=summary`;

  return (
    <div>
      <PageTitle
        isFullWidth
        subtitle={
          <HStack justify="between" wrap>
            <H3>{`ID: ${appointmentId}`}</H3>
            <HStack inline wFull={false}>
              <LinkButton
                href={datadogUrl}
                text="View Datadog Logs"
                type="outline"
              />
              <LinkButton text="View in App" to={inAppUrl} type="outline" />
            </HStack>
          </HStack>
        }
      >
        {appointment?.name}
      </PageTitle>
      <TabNav
        tabs={[
          {
            name: 'Details',
            link: 'details',
          },
          {
            name: 'Summary and Transcript',
            link: 'artifacts/summary',
          },
          {
            name: 'Files',
            link: 'files',
          },
          {
            name: 'Live vs Batch Transcript Comparison',
            link: 'transcript-comparison',
          },
          {
            name: 'Source Artifacts',
            link: 'source-artifacts',
          },
        ]}
      />
      <div className="mt-4">
        <Suspense fallback={'Loading...'}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  );
};
