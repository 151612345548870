import { useSuspenseQuery } from '@apollo/client';
import React from 'react';

import { DebugJson, P } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

import { useAppointmentParams } from './useAppointmentParams';

const getAppointmentSummaryQuery = graphql(`
  query getAppointmentSummary($tenantId: uuid!, $appointmentId: uuid!) {
    appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
      __typename
      id
      humanOutputs {
        __typename
        humanOutputId
        appointmentId
        output {
          __typename
          id
          content
        }
      }
      appointment_llm_outputs(
        where: {
          output: {
            modelPromptTemplate: {
              template: { outputType: { _in: [SOAP_NOTE, DYNAMIC_OUTPUT] } }
            }
          }
        }
      ) {
        __typename
        llmOutputId
        appointmentId
        output {
          __typename
          id
          content
          modelPromptTemplate {
            __typename
            id
            model_args {
              __typename
              id
              name
              modelType
              args
            }
            template {
              __typename
              id
              name
              outputType
            }
          }
        }
      }
    }
  }
`);

export interface AppointmentSummaryProps {}

export const AppointmentSummary: React.FC<AppointmentSummaryProps> = () => {
  const { tenantId, appointmentId } = useAppointmentParams();

  const { data } = useSuspenseQuery(getAppointmentSummaryQuery, {
    variables: {
      tenantId,
      appointmentId,
    },
  });

  // TODO(jesse)[ELU-2923] Implement appointment summary
  return (
    <div>
      <span>Appointment Summary</span>
      <P>Coming soon</P>
      <DebugJson value={data} />
    </div>
  );
};
