import { useApolloClient } from '@apollo/client';
import { createBrowserInspector } from '@statelyai/inspect';
import { createActorContext } from '@xstate/react';

import { FCC } from '@eluve/components';
import { AppointmentContextProvider } from '@eluve/frontend-appointment-hooks';
import { useTenantUserSettings } from '@eluve/frontend-feature-user-settings';
import { useNamedLogger } from '@eluve/logger';
import { useTenantIdFromParams } from '@eluve/session-helpers';
import { sessionSoundPlayer } from '@eluve/sounds';
import {
  AppointmentTask,
  useDb,
  useUserFileSystemStore,
} from '@eluve/user-local-files';
import { useAppointmentTasksActor } from '@eluve/user-local-files';

import { MicrophoneFailedDialog } from './components/MicrophoneFailedDialog';
import { RecordingFailedDialog } from './components/RecordingFailedDialog';
import { TranscriptionStateTransitionLogger } from './components/TranscriptionStateTransitionLogger';
import { transcriptionMachine } from './transcription.machine';

const getInspect = () => {
  if (process.env.NODE_ENV !== 'development') {
    return undefined;
  }

  const IgnoredInspectorEvents: string[] = [];
  const { inspect } = createBrowserInspector({
    autoStart: false,
    filter: (inspEvent) => {
      if (inspEvent.type === '@xstate.event') {
        return !IgnoredInspectorEvents.includes(inspEvent.event.type);
      }
      if (inspEvent.type === '@xstate.snapshot') {
        return !IgnoredInspectorEvents.includes(inspEvent.event.type);
      }
      return true;
    },
  });

  return inspect;
};

const TranscriptionMachineContext = createActorContext(transcriptionMachine, {
  inspect: getInspect(),
});

export const useTranscriptionMachineSelector =
  TranscriptionMachineContext.useSelector;

export const useTranscriptionMachineActor =
  TranscriptionMachineContext.useActorRef;

const ActiveAppointmentContextProvider: FCC = ({ children }) => {
  const tenantId = useTenantIdFromParams();
  const appointmentId = useTranscriptionMachineSelector(
    (s) => s.context.appointmentId,
  );

  if (!tenantId || !appointmentId) {
    return children;
  }

  return (
    <AppointmentContextProvider
      tenantId={tenantId}
      appointmentId={appointmentId}
    >
      {children}
    </AppointmentContextProvider>
  );
};

export const TranscriptionMachineProvider: FCC = ({ children }) => {
  const tenantId = useTenantIdFromParams();
  const apolloClient = useApolloClient();
  const { send } = useAppointmentTasksActor();
  const { settings } = useTenantUserSettings();
  const logger = useNamedLogger('TranscriptionMachine');
  const db = useDb();

  sessionSoundPlayer.setIsEnabled(settings?.enableSoundEffects ?? true);

  const handleAddTask = (task: AppointmentTask) => {
    send(task);
  };

  const userFileSystem = useUserFileSystemStore(
    (state) => state.userFileSystem,
  );

  return (
    <TranscriptionMachineContext.Provider
      options={{
        input: {
          tenantId: tenantId ?? '',
          userFileSystem,
          addAppointmentTaskInQueue: handleAddTask,
          apolloClient,
          logger,
          db,
        },
      }}
    >
      <ActiveAppointmentContextProvider>
        {children}
        <MicrophoneFailedDialog />
        <RecordingFailedDialog />
        <TranscriptionStateTransitionLogger />
      </ActiveAppointmentContextProvider>
    </TranscriptionMachineContext.Provider>
  );
};
