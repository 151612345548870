import { match } from 'ts-pattern';

import { SupportedVendors } from '@eluve/graphql-types';

import { ExperityVendor } from './experity/experity.vendor';
import { JaneVendor } from './jane/jane.vendor';
import { MockVendor } from './mock/mock.vendor';
import { MovementXVendor } from './movement-x/movement-x.vendor';
import { TebraVendor } from './tebra/tebra.vendor';
import { Vendor } from './vendor';

export const getVendorProvider = (vendor: SupportedVendors): Vendor => {
  return match(vendor)
    .with('JANE', () => new JaneVendor())
    .with('EXPERITY', () => new ExperityVendor())
    .with('TEBRA', () => new TebraVendor())
    .with('MOVEMENT_X', () => new MovementXVendor())
    .with('BLVD', () => new MockVendor())
    .with('SILOAM', () => new MockVendor())
    .exhaustive();
};
