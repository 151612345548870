import { useLazyQuery } from '@apollo/client';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import debounce from 'lodash/debounce';
import { X } from 'lucide-react';
import React, { useCallback, useMemo } from 'react';
import {
  MultiValueGenericProps,
  MultiValueRemoveProps,
  OptionProps,
  PlaceholderProps,
  components,
} from 'react-select';
import AsyncSelect from 'react-select/async';

import { MedicalCode, MedicalCodeTag } from '@eluve/blocks';
import { HStack, Icon, tv } from '@eluve/components';
import { MedicalCodeTypesEnum } from '@eluve/graphql-types';
import { ResultOf } from '@eluve/graphql.tada';

import { searchBillingCodesQuery } from './SearchableBillingCodes.operations';

export type BillingCodeOption = Omit<
  ResultOf<typeof searchBillingCodesQuery>['searchMedicalCodes'][0],
  '__typename'
>;

const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <X className="size-[10px]" />
    </components.MultiValueRemove>
  );
};

const Placeholder = (props: PlaceholderProps<BillingCodeOption>) => {
  return (
    <HStack className="col-start-1 col-end-3 row-start-1 row-end-2 inline-flex gap-3 pl-2">
      <Icon size="xs" name="Search" />
      <components.Placeholder {...props} />
    </HStack>
  );
};

// We'll let the container take care of rendering the whole billing code
// so we don't need an actual label
const MultiValueLabel = (_props: MultiValueGenericProps<BillingCodeOption>) => {
  return null;
};

const MultiValueContainer = (
  props: MultiValueGenericProps<BillingCodeOption>,
) => {
  const { data } = props;
  const code = data as BillingCodeOption;

  return (
    <MedicalCode
      code={code.code}
      codeType={code.codeType}
      includeDescription={true}
      description={code.description ?? ''}
      endAdornment={<components.MultiValueRemove {...props} />}
    />
  );
};

const Option = (props: OptionProps<BillingCodeOption>) => {
  const { data } = props;
  const code = data as BillingCodeOption;

  return (
    <components.Option {...props} className="flex items-center gap-3">
      <MedicalCodeTag code={code.code} codeType={code.codeType} />
      <div>{code.description}</div>
    </components.Option>
  );
};

export interface SearchableBillingCodesProps {
  codeTypes?: MedicalCodeTypesEnum[];
  selectedCodes?: BillingCodeOption[];
  shouldRenderValue?: boolean;
  onCodeAdded?: (option: BillingCodeOption) => void | Promise<void>;
  onCodeRemoved?: (option: BillingCodeOption) => void | Promise<void>;
}

// This ensures that Emotion's styles are inserted before Tailwind's styles so that Tailwind classes have precedence over Emotion
// https://react-select.com/styles#the-classnames-prop
const EmotionCacheProvider = ({ children }: { children: React.ReactNode }) => {
  const cache = React.useMemo(
    () =>
      createCache({
        key: 'with-tailwind',
        insertionPoint: document.querySelector('title')!,
      }),
    [],
  );

  return <CacheProvider value={cache}>{children}</CacheProvider>;
};

const styles = tv({
  slots: {
    container: 'w-full',
    control: 'rounded-[10px] border border-brandGray200 p-2',
    valueContainer: 'gap-2 p-0',
    menu: 'z-[9999] mt-3 rounded-[12px] p-0 shadow-menu',
    menuList: 'p-3',
    placeholder: 'py-1 text-brandGray600',
    multiValue: 'bg-transparent',
    indicatorSeparator: 'hidden',
    dropdownIndicator: 'hidden',
    loadingIndicator: 'hidden',
    indicatorsContainer: 'hidden',
    multiValueRemove: 'rounded-full bg-brandGray800 p-0.5 text-brandGray50',
    option: 'rounded-[8px] p-3',
  },
  variants: {
    optionFocused: {
      true: {
        option: 'bg-brandGray200',
      },
    },
  },
});

const style = styles();

const defaultCodeTypes: MedicalCodeTypesEnum[] = ['CPT', 'ICD_10', 'SNOMED'];

export const SearchableBillingCodes: React.FC<SearchableBillingCodesProps> = ({
  codeTypes = [],
  selectedCodes,
  shouldRenderValue,
  onCodeAdded,
  onCodeRemoved,
}) => {
  const [search] = useLazyQuery(searchBillingCodesQuery);

  const _searchCodes = useCallback(
    (query: string, callback: (codes: BillingCodeOption[]) => void) => {
      const types = codeTypes.length ? codeTypes : defaultCodeTypes;
      search({ variables: { query, limit: 25, types } }).then((result) => {
        callback(result.data?.searchMedicalCodes ?? []);
      });
    },
    [codeTypes, search],
  );

  const searchCodes = useMemo(
    () => debounce(_searchCodes, 250),
    [_searchCodes],
  );

  return (
    <EmotionCacheProvider>
      <AsyncSelect<BillingCodeOption, true>
        controlShouldRenderValue={shouldRenderValue}
        backspaceRemovesValue={!shouldRenderValue}
        placeholder="Choose a code you would like to add"
        noOptionsMessage={({ inputValue }) =>
          inputValue
            ? `No results for "${inputValue}"`
            : 'Enter code or search term'
        }
        loadingMessage={() => 'Searching...'}
        isMulti
        value={selectedCodes}
        isClearable={false}
        classNames={{
          container: () => style.container(),
          control: () => style.control(),
          valueContainer: () => style.valueContainer(),
          menu: () => style.menu(),
          menuList: () => style.menuList(),
          placeholder: () => style.placeholder(),
          multiValue: () => style.multiValue(),
          indicatorSeparator: () => style.indicatorSeparator(),
          dropdownIndicator: () => style.dropdownIndicator(),
          loadingIndicator: () => style.loadingIndicator(),
          indicatorsContainer: () => style.indicatorsContainer(),
          multiValueRemove: () => style.multiValueRemove(),
          option: ({ isFocused }) => style.option({ optionFocused: isFocused }),
        }}
        onChange={async (input) => {
          const codes = selectedCodes ?? [];
          const removed = codes.filter((f) => !input.includes(f));
          const added = input.filter((f) => !codes.includes(f));

          if (removed.length) {
            const [toRemove] = removed;
            await onCodeRemoved?.(toRemove!);
          }

          if (added.length) {
            const [toAdd] = added;
            await onCodeAdded?.(toAdd!);
          }
        }}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.code}
        components={{
          MultiValueContainer,
          MultiValueLabel,
          MultiValueRemove,
          Option,
          Placeholder,
        }}
        loadOptions={searchCodes}
      />
    </EmotionCacheProvider>
  );
};
