import { useMutation } from '@apollo/client';
import { WandSparkles } from 'lucide-react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useMount } from 'react-use';
import { toast } from 'sonner';
import { match } from 'ts-pattern';

import { CopyRichTextButton } from '@eluve/blocks';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  H4,
  P,
  VStack,
} from '@eluve/components';
import { HStack } from '@eluve/components';
import {
  useAppointmentActivity,
  useAppointmentId,
  useIsAppointmentReadonly,
  useSummary,
} from '@eluve/frontend-appointment-hooks';
import {
  AppointmentBillingCodes,
  AppointmentLanguageSelector,
} from '@eluve/frontend-feature-appointment';
import { graphql } from '@eluve/graphql.tada';
import { ClassicSummary, DynamicSummary } from '@eluve/llm-outputs';
import { useNamedLogger } from '@eluve/logger';
import { FeatureFlagKeysLookup } from '@eluve/posthog';
import { useCaptureEvent, useFeatureFlagPayload } from '@eluve/posthog-react';
import { useAssignedTenantIdFromParams } from '@eluve/session-helpers';
import {
  CustomInstructions,
  CustomInstructionsSidebarTrigger,
  DynamicArtifactRenderer,
  DynamicArtifactSaveContextProps,
  FeatureFlaggedComponent,
  RegenerateSummaryButton,
  RollbackPreviousSummaryButton,
  SummaryFeedbackModal,
  SummarySectionMarkdown,
  UserFeatureFlaggedComponent,
} from '@eluve/smart-blocks';
import { AppointmentSummaryKeys, convertArrayToPgLiteral } from '@eluve/utils';

import { SummarizationErrorCard } from '../SummarizationErrorCard';
import { useSummarizationInProgress } from '../hooks/useSummarizationInProgress';
import { useEditSummary } from '../useEditSummary';

import { pendingUnsavedChangesStore } from './AppointmentLastSaved';
import { ClientRecapMailToAction } from './ClientRecapMailToAction';

const updateHumanOutputContentMutation = graphql(`
  mutation updateHumanOutputContent($id: uuid!, $path: _text!, $value: jsonb!) {
    updateHumanOutputContent(args: { _id: $id, path: $path, value: $value }) {
      __typename
      id
      content
      updatedAt
    }
  }
`);

const SummaryInProgress = () => (
  <Box
    vStack
    fullWidth
    className="max-w-[500px] items-center gap-8 self-center pt-9 text-center"
  >
    <h4 className="text-2xl text-gray-12">Generating Notes...</h4>
    <p className="text-lg text-gray-11">
      You can leave this page and start a new session! We&apos;ll let you know
      when your note is ready.
    </p>
  </Box>
);

type SummaryProps = {
  llmOutputId: string | null;
  humanOutputId: string | null;
};

const SummaryInProgressTakingLonger = () => (
  <Box
    vStack
    fullWidth
    className="max-w-[500px] items-center gap-8 self-center pt-9 text-center"
  >
    <h4 className="text-2xl text-gray-12">
      This is taking longer than expected...
    </h4>
    <p className="text-lg text-gray-11">
      We're still working on your notes. You can leave this page and start a new
      session! We&apos;ll let you know when your note is ready.
    </p>
  </Box>
);

const SummaryFailed: React.FC = () => {
  const logger = useNamedLogger('AppointmentSummaryFailed');

  useMount(() => {
    logger.error('No summary available to display to user');
  });

  return (
    <Box
      vStack
      fullWidth
      className="max-w-[470px] items-center gap-8 self-center py-9 text-center"
    >
      <h4 className="text-2xl text-gray-12">Failed to generate notes</h4>
      <p className="text-lg text-gray-11">
        We were unable to generate notes for this appointment. We alerted the
        team and will investigate the issue.
      </p>
      <p className="text-lg text-gray-11">
        In the meantime you might try regenerating the summary using a different
        template.
      </p>
      <RegenerateSummaryButton />
    </Box>
  );
};

const ClassicSummaryResult: React.FC<
  SummaryProps & { summary: ClassicSummary }
> = ({ llmOutputId, summary }) => {
  const appointmentId = useAppointmentId();
  const data = summary.data ?? {};
  const editSummary = useEditSummary();
  const isReadonly = useIsAppointmentReadonly();
  return (
    <Box vStack className="gap-4 lg:gap-6">
      {Object.entries(data).map(([summaryKey, summaryValue]) => {
        return (
          <SummarySectionMarkdown
            className="w-full"
            appointmentId={appointmentId}
            key={summaryKey + llmOutputId}
            summaryKey={summaryKey as AppointmentSummaryKeys}
            sectionTitle={summaryKey}
            content={summaryValue ?? ''}
            disabled={isReadonly}
            handleContentChange={(key, content) => {
              pendingUnsavedChangesStore.setState(true);
              editSummary(key, content);
            }}
            additionalActions={
              summaryKey === 'CLIENT_RECAP'
                ? [ClientRecapMailToAction]
                : undefined
            }
          />
        );
      })}
    </Box>
  );
};

const DynamicSummaryResult: React.FC<
  SummaryProps & { summary: DynamicSummary }
> = ({ summary, llmOutputId, humanOutputId }) => {
  const isReadonly = useIsAppointmentReadonly();

  const [updateHumanOutputContent] = useMutation(
    updateHumanOutputContentMutation,
  );

  const saveBlock: DynamicArtifactSaveContextProps['saveBlock'] | undefined =
    useMemo(() => {
      if (isReadonly || !humanOutputId) {
        return undefined;
      }

      return async (blockPath, value) => {
        if (!blockPath || value === undefined) {
          return;
        }

        await updateHumanOutputContent({
          variables: {
            id: humanOutputId,
            path: convertArrayToPgLiteral(blockPath),
            value: value as Record<string, unknown>,
          },
          onCompleted: () => pendingUnsavedChangesStore.setState(false),
          onError: () => toast.error('Failed to save block'),
        });
      };
    }, [isReadonly, updateHumanOutputContent, humanOutputId]);

  const onPendingChanges: DynamicArtifactSaveContextProps['onPendingChanges'] =
    useCallback(() => pendingUnsavedChangesStore.setState(true), []);

  return (
    <DynamicArtifactRenderer
      key={llmOutputId}
      blocks={summary.blocks}
      isReadonly={isReadonly}
      saveBlock={saveBlock}
      onPendingChanges={onPendingChanges}
    />
  );
};

const SummaryResult = () => {
  const currentSummary = useSummary();
  const summary = currentSummary?.summary ?? {};
  const summaryDetected = Object.values(summary).some((s) => s?.length);
  const appointmentId = useAppointmentId();
  const contentRef = useRef<HTMLDivElement>(null);

  const isReadonly = useIsAppointmentReadonly();
  const flag = FeatureFlagKeysLookup.NOTE_REGENERATION_WITH_CUSTOM_INSTRUCTIONS;
  const exampleChips = useFeatureFlagPayload(flag);
  const [instructions, setInstructions] = useState<string>('');
  const tenantId = useAssignedTenantIdFromParams();

  const captureEvent = useCaptureEvent({
    tenantId,
    appointmentId,
  });

  if (!currentSummary.isSummaryAvailable) {
    return null;
  }

  return (
    <div className="flex w-full flex-col">
      {!isReadonly && (
        <VStack
          justify="between"
          wFull
          className="pb-5 lg:flex-row lg:items-center"
        >
          <HStack gap={4} className="flex-wrap">
            <RegenerateSummaryButton />
            <UserFeatureFlaggedComponent flag="NOTE_REGENERATION_WITH_CUSTOM_INSTRUCTIONS">
              <CustomInstructions
                instructions={instructions}
                setInstructions={setInstructions}
              />
            </UserFeatureFlaggedComponent>
            <RollbackPreviousSummaryButton />
            <CopyRichTextButton
              getContentElement={() => contentRef.current}
              variant="outlinesecondary"
              hideContentInTooltip
              size="xs"
              className="gap-2"
            >
              Copy Summary
            </CopyRichTextButton>
          </HStack>

          <div>
            <FeatureFlaggedComponent flag="MULTILINGUAL_SUPPORT">
              <AppointmentLanguageSelector outputLanguage regenerateSummary />
            </FeatureFlaggedComponent>
          </div>
        </VStack>
      )}

      <SummarizationErrorCard />

      <div ref={contentRef} className="w-full">
        {match(currentSummary.isSummaryAvailable)
          .with(true, () => {
            return match(summary)
              .with({ type: 'SOAP' }, (soapSummary) => (
                <ClassicSummaryResult
                  llmOutputId={currentSummary.llmOutputId}
                  humanOutputId={currentSummary.humanOutputId}
                  summary={soapSummary}
                />
              ))
              .otherwise((summary) => (
                <DynamicSummaryResult
                  llmOutputId={currentSummary.llmOutputId}
                  humanOutputId={currentSummary.humanOutputId}
                  summary={summary}
                />
              ));
          })
          .otherwise(() => null)}
      </div>

      <Divider className="my-7 border-brandGray300" />

      <FeatureFlaggedComponent flag="BILLING_CODES">
        <AppointmentBillingCodes
          isReadonly={isReadonly}
          appointmentId={appointmentId}
          tenantId={tenantId}
          shouldPollForResults
        />
      </FeatureFlaggedComponent>

      {summaryDetected && (
        <div>
          <Box className="my-8 flex w-full flex-row items-center justify-between gap-2 rounded-lg bg-brandGray100 p-4">
            <SummaryFeedbackModal
              appointmentId={appointmentId}
              llmOutputId={currentSummary?.llmOutputId}
            />
          </Box>

          {!isReadonly && (
            <UserFeatureFlaggedComponent flag="NOTE_REGENERATION_WITH_CUSTOM_INSTRUCTIONS">
              <Card className="mb-10 w-full rounded-xl border-gray-4">
                <CardHeader className="flex h-10 flex-row rounded-t-xl bg-white p-5">
                  <div className="flex flex-row gap-3">
                    <WandSparkles />
                    <H4>Improve your notes</H4>
                  </div>
                </CardHeader>
                <CardContent className="flex flex-col items-start rounded-b-xl bg-white p-3">
                  {exampleChips?.map((chip, index) => {
                    return (
                      <div key={chip} className="w-full pl-2 pr-2">
                        <CustomInstructionsSidebarTrigger alwaysShown={true}>
                          <Button
                            variant="outline"
                            className="border-none bg-white pb-6 pl-0 pr-0 pt-6"
                            onClick={() => {
                              captureEvent(
                                'user_clicked_top_level_improve_notes_chip',
                                {
                                  instructions,
                                },
                              );
                              setInstructions(chip);
                            }}
                          >
                            <P className="font-normal">{chip}</P>
                          </Button>
                        </CustomInstructionsSidebarTrigger>
                        {index !== exampleChips.length - 1 && (
                          <hr className="bg-gray-4" />
                        )}
                      </div>
                    );
                  })}
                </CardContent>
              </Card>
            </UserFeatureFlaggedComponent>
          )}
        </div>
      )}
    </div>
  );
};

export const AppointmentSummaryResult = () => {
  const { latestSummaryStatus, latestSummaryNumAttempts } =
    useAppointmentActivity();
  const isSummarizationInProgress = useSummarizationInProgress();

  if (isSummarizationInProgress) {
    if (latestSummaryNumAttempts && latestSummaryNumAttempts < 2) {
      return <SummaryInProgress />;
    } else {
      return <SummaryInProgressTakingLonger />;
    }
  }

  if (latestSummaryStatus === 'FAILED') {
    return <SummaryFailed />;
  }

  return <SummaryResult />;
};
