import { z } from 'zod';

import { AppointmentModel } from '../../models/appointment';
import { PatientType } from '../../models/patient';
import {
  CanSyncToEhrArgs,
  GetEhrDataOptions,
  Vendor,
  VendorSyncConfig,
} from '../vendor';

import { convertExperityDataToAppointmentModel } from './experity.appointment';
import { logo } from './logo';
import { appointmentSchema } from './types';

const appointmentData = z.object({
  appointments: z.array(z.unknown()),
});

export class ExperityVendor implements Vendor {
  getChart = (data: unknown) => {
    throw new Error('Method not implemented.');
  };

  getAppointments = (
    data: unknown,
    options: GetEhrDataOptions = {},
  ): AppointmentModel[] => {
    const appointments: AppointmentModel[] = [];
    const rawAppointments = appointmentData.parse(data);

    for (const appointment of rawAppointments.appointments) {
      const appointmentParseResult = appointmentSchema.safeParse(appointment);
      if (!appointmentParseResult.success) {
        throw new Error(
          `Invalid appointment data from 3rd party EHR: ${appointmentParseResult.error}`,
        );
      }

      const appointmentModel = convertExperityDataToAppointmentModel(
        appointmentParseResult.data,
        options,
      );
      appointments.push(appointmentModel);
    }

    return appointments;
  };

  getPatient = (): PatientType => {
    throw new Error('Method not implemented.');
  };

  getPatientEhrUrl = () => {
    return `https://pvpm.practicevelocity.com/24_2/LogDetail.aspx`;
  };

  getLogo = () => logo;

  getHomeUrl = (domain: string) => {
    return `https://${domain}`;
  };

  getSyncConfig(): VendorSyncConfig {
    return {
      canSignNoteInEhr: false,
      canSyncNoteToEhr: (data: CanSyncToEhrArgs) => {
        return Boolean(data.externalPatientId);
      },
      canSyncWithManualChartUrl: false,
    };
  }
}
