import { match } from 'ts-pattern';

import { useApiClient } from '@eluve/api-client-provider';
import { combineSummaryWithNotes } from '@eluve/frontend-feature-ehr-sync';
import { SupportedVendors } from '@eluve/graphql-types';
import { ClassicSummary, DynamicSummary } from '@eluve/llm-outputs';
import { MessageClient } from '@eluve/message-client';
import { MessageResponse, WebAppContract } from '@eluve/vendor';

type SyncToEhrArgs = {
  tenantId: string;
  appointmentId: string;
  vendor: SupportedVendors;
  domain: string;
  patientExternalId: string;
  appointmentName: string;
  additionalUserNotes?: string | null;
  shouldSignExternalChart: boolean;
  appointmentExternalId?: string;
  summary: ClassicSummary | DynamicSummary;
  templateExternalId?: string;
};

export type SyncToEhrResponse = MessageResponse<{
  chartUrl: string;
  chartId: string;
}>;

export const useSyncToEhr = ({
  messageClient,
}: {
  messageClient: MessageClient<WebAppContract>['proxy'];
}) => {
  const api = useApiClient();

  return async (args: SyncToEhrArgs): Promise<SyncToEhrResponse> => {
    const {
      appointmentId,
      vendor,
      domain,
      patientExternalId,
      appointmentName,
      additionalUserNotes,
      shouldSignExternalChart,
      summary,
      appointmentExternalId,
      tenantId,
      templateExternalId,
    } = args;

    const summaryData =
      summary.type === 'SOAP' && summary.data ? summary.data : summary;

    return match(vendor)
      .with('JANE', async (vendor) => {
        return messageClient.syncToEhr({
          vendor,
          domain,
          data: {
            sign: shouldSignExternalChart,
            summary: combineSummaryWithNotes(summaryData, additionalUserNotes),
            patientExternalId: patientExternalId!,
            description: appointmentName,
            appointmentExternalId,
            templateExternalId,
          },
        });
      })
      .with('TEBRA', 'MOVEMENT_X', async (vendor) => {
        return messageClient.syncToEhr({
          vendor,
          domain,
          data: {
            sign: shouldSignExternalChart,
            summary: combineSummaryWithNotes(summaryData, additionalUserNotes),
            patientExternalId: patientExternalId!,
            description: appointmentName,
            appointmentExternalId,
          },
        });
      })
      .with('EXPERITY', async (vendor) => {
        const pdfResult = await api.appointments.getOrCreatePdfUrl({
          params: {
            appointmentId,
            tenantId,
          },
        });

        if (pdfResult.status !== 201) {
          throw new Error('Failed to get PDF URL for appointment');
        }

        return messageClient.syncToEhr({
          tenantId,
          vendor,
          domain,
          data: {
            patientExternalId: patientExternalId!,
            appointmentExternalId,
            category: 'Transcription',
            tenant: 'CRHGA',
            description: appointmentName,
            pdfUrl: pdfResult.body.pdfUrl,
          },
        });
      })
      .with('BLVD', 'SILOAM', async (vendor) => {
        return { ok: false, error: `${vendor} is not immplented` };
      })
      .exhaustive();
  };
};
