import { useMutation, useQuery } from '@apollo/client';
import { produce } from 'immer';
import React, { useEffect } from 'react';
import { toast } from 'sonner';
import { v4 } from 'uuid';

import { cacheUtils } from '@eluve/apollo-client';
import { MedicalCode } from '@eluve/blocks';
import { H4, HStack, Icon, VStack, textStyles } from '@eluve/components';
import { FragmentOf } from '@eluve/graphql.tada';

import {
  acceptBillingCodeRecommendationMutation,
  addAppointmentBillingCodeMutation,
  appointmentBillingCodesFragment,
  getAppointmentBillingCodes,
  medicalCodeFragment,
  removeBillingCodeMutation,
} from './AppointmentBillingCodes.operations';
import {
  BillingCodeOption,
  SearchableBillingCodes,
} from './SearchableBillingCodes';

type MedicalCodeFragment = FragmentOf<typeof medicalCodeFragment>;

export interface AppointmentBillingCodesProps {
  tenantId: string;
  appointmentId: string;
  isReadonly?: boolean;
  shouldPollForResults?: boolean;
}

export const AppointmentBillingCodes: React.FC<
  AppointmentBillingCodesProps
> = ({
  tenantId,
  appointmentId,
  isReadonly = false,
  shouldPollForResults = false,
}) => {
  const { data, loading, startPolling, stopPolling } = useQuery(
    getAppointmentBillingCodes,
    {
      variables: {
        tenantId,
        appointmentId,
      },
    },
  );

  const billingCodes = data?.appointmentsByPk?.billingCodes ?? [];

  const recommendations =
    data?.appointmentsByPk?.billing_code_recommendations ?? [];

  const codeRecommendationsToShow =
    recommendations.filter((c) => !c.isFinal && Boolean(c.medical_code)) ?? [];

  useEffect(() => {
    if (shouldPollForResults && !isReadonly) {
      startPolling(2500);
      return () => {
        stopPolling();
      };
    }
  }, [shouldPollForResults, isReadonly, startPolling, stopPolling]);

  useEffect(() => {
    if (recommendations.length || billingCodes.length) {
      stopPolling();
    }
  }, [recommendations.length, billingCodes.length, stopPolling]);

  const [acceptRecommendation] = useMutation(
    acceptBillingCodeRecommendationMutation,
    {
      onError: () => toast.error('Failed to accept recommendation'),
      onCompleted: () => toast.success('Recommendation accepted'),
      optimisticResponse: ({ appointmentId, billingCodeId }) => ({
        insertAppointmentBillingCodesOne: {
          __typename: 'AppointmentBillingCodes' as const,
          id: v4(),
        },
        updateAppointmentBillingCodeRecommendations: {
          returning: [
            {
              __typename: 'AppointmentBillingCodeRecommendations' as const,
              appointmentId,
              codeId: billingCodeId,
              isFinal: true,
            },
          ],
        },
      }),
    },
  );

  const [removeBillingCode] = useMutation(removeBillingCodeMutation, {
    onError: () => toast.error('Failed to remove billing code'),
  });

  const [addBillingCode] = useMutation(addAppointmentBillingCodeMutation, {
    optimisticResponse: () => ({
      insertAppointmentBillingCodesOne: {
        __typename: 'AppointmentBillingCodes' as const,
        id: v4(),
      },
    }),
  });

  const onRemoveBillingCode = (codeId: string) => {
    if (isReadonly) {
      return;
    }

    removeBillingCode({
      variables: {
        appointmentId,
        billingCodeId: codeId,
      },
      optimisticResponse: ({ appointmentId, billingCodeId }) => {
        return {
          deleteAppointmentBillingCodes: {
            returning: [
              {
                __typename: 'AppointmentBillingCodes' as const,
                billingCodeId,
              },
            ],
          },
          updateAppointmentBillingCodeRecommendations: {
            returning: [
              {
                __typename: 'AppointmentBillingCodeRecommendations' as const,
                appointmentId,
                codeId: billingCodeId,
                isFinal: false,
              },
            ],
          },
        };
      },
      update: (_cache) => {
        cacheUtils.updateFragment(
          {
            fragment: appointmentBillingCodesFragment,
            key: {
              id: appointmentId,
            },
          },
          (existing) => {
            if (!existing) {
              return existing;
            }
            // Optimistically remove the billing code from the list
            return produce(existing, (draft) => {
              const idx = draft.billingCodes.findIndex(
                (c) => c.medical_code.id === codeId,
              );
              if (idx !== -1) {
                draft.billingCodes.splice(idx, 1);
              }
            });
          },
        );
      },
    });
  };

  const onAddBillingCode = (code: BillingCodeOption) => {
    if (isReadonly) {
      return;
    }

    addBillingCode({
      variables: {
        appointmentId,
        billingCodeId: code.id,
      },
      update: (_cache) => {
        cacheUtils.updateFragment(
          {
            fragment: appointmentBillingCodesFragment,
            key: {
              id: appointmentId,
            },
          },
          (existing) => {
            if (!existing) {
              return existing;
            }

            // Optimistically add the billing code to the list of accepted billing codes
            return produce(existing, (draft) => {
              draft.billingCodes.push({
                __typename: 'AppointmentBillingCodes',
                id: v4(),
                medical_code: {
                  __typename: 'MedicalCodes',
                  ...code,
                },
              });
            });
          },
        );
      },
    });
  };

  const onAcceptRecommendation = ({
    code: { __typename, ...medicalCode },
  }: {
    code: MedicalCodeFragment;
  }) => {
    if (isReadonly) {
      return;
    }
    acceptRecommendation({
      variables: {
        appointmentId,
        billingCodeId: medicalCode.id,
      },
      update: (_cache) => {
        cacheUtils.updateFragment(
          {
            fragment: appointmentBillingCodesFragment,
            key: {
              id: appointmentId,
            },
          },
          (existing) => {
            if (!existing) {
              return existing;
            }

            // Optimistically add the billing code to the list of accepted billing codes
            return produce(existing, (draft) => {
              draft.billingCodes.push({
                __typename: 'AppointmentBillingCodes',
                id: v4(),
                medical_code: {
                  __typename: 'MedicalCodes',
                  ...medicalCode,
                },
              });
            });
          },
        );
      },
    });
  };

  if (loading) {
    return null;
  }

  if (isReadonly && billingCodes.length === 0) {
    return null;
  }

  return (
    <VStack gap={4} className="mb-10">
      <HStack gap={3}>
        <H4>Billing Codes</H4>
      </HStack>
      <VStack gap={6}>
        <HStack className="z-30">
          <SearchableBillingCodes
            selectedCodes={billingCodes.map((c) => c.medical_code)}
            onCodeRemoved={(code) => onRemoveBillingCode(code.id)}
            onCodeAdded={onAddBillingCode}
          />
        </HStack>
        {Boolean(codeRecommendationsToShow.length && !isReadonly) && (
          <VStack gap={3}>
            <h6
              className={textStyles.body({
                weight: 'semibold',
                size: 's',
                className: 'uppercase text-brandGray600',
              })}
            >
              Recommended Codes
            </h6>
            <HStack gap={3} wrap>
              {codeRecommendationsToShow.map(({ medical_code }) => {
                const {
                  id: codeId,
                  code,
                  codeType,
                  description,
                } = medical_code!;
                return (
                  <MedicalCode
                    onClick={() => {
                      onAcceptRecommendation({
                        code: medical_code!,
                      });
                    }}
                    startAdornment={
                      <div>
                        <Icon
                          size="xxs"
                          name="Plus"
                          className="text-brandGray500"
                        />
                      </div>
                    }
                    isRecommended
                    key={codeId}
                    code={code}
                    codeType={codeType}
                    description={description ?? ''}
                  />
                );
              })}
            </HStack>
          </VStack>
        )}
      </VStack>
    </VStack>
  );
};
