import { useQuery } from '@apollo/client';
import { useReactTable } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { match } from 'ts-pattern';

import { QUERY_ROOT_ID, useCompleteFragment } from '@eluve/apollo-client';
import { AppointmentStatusLabel } from '@eluve/blocks';
import {
  ColDefBuilder,
  DataTableFilterField,
  PageTitle,
  ServerDataTable,
  useDataTableQueryState,
} from '@eluve/components';
import {
  AppointmentStatusEnum,
  AppointmentsOrderBy,
} from '@eluve/graphql-types';

import {
  reviewerTenantsFragment,
  reviewerUsersFragment,
  searchAppointmentsQuery,
} from './operations';

type TableRow = {
  tenantId: string;
  tenantName: string;
  appointmentId: string;
  appointmentName: string;
  userId: string;
  userEmail: string;
  updatedAt: string;
  status: AppointmentStatusEnum;
};

const columns = new ColDefBuilder<TableRow>()
  .detailsLink((row) => `${row.tenantId}/${row.appointmentId}`, 'Appointment')
  .defaultSortable('tenantId', {
    label: 'Tenant',
    cellRenderer: (row) => row.tenantName,
  })
  .defaultSortable('userId', {
    label: 'User',
    cellRenderer: (row) => row.userEmail,
  })
  .dateSortable('updatedAt', 'Updated At')
  .defaultSortable('status', {
    cellRenderer: (row) => (
      <AppointmentStatusLabel
        // TODO(jesse)[ELU-2881]: Make these value available without having to do so many joins
        // in the query
        isSigned={false}
        isSummarized={false}
        status={row.status}
      />
    ),
  })
  .build();

export const ReviewAppointmentsPage: React.FC = () => {
  const { tenants } = useCompleteFragment({
    fragment: reviewerTenantsFragment,
    key: QUERY_ROOT_ID,
  });

  const { users } = useCompleteFragment({
    fragment: reviewerUsersFragment,
    key: QUERY_ROOT_ID,
  });

  const filterFields: DataTableFilterField<TableRow>[] = useMemo(() => {
    const fields: DataTableFilterField<TableRow>[] = [
      {
        id: 'tenantId',
        label: 'Tenant',
        options: tenants.map((t) => ({
          label: t.name,
          value: t.id,
        })),
      },
      {
        id: 'userId',
        label: 'User',
        options: users.map((u) => ({
          label: u.email,
          value: u.id,
        })),
      },
      {
        id: 'status',
        label: 'Status',
        options: [
          { label: 'ACTIVE', value: 'ACTIVE' },
          { label: 'COMPLETED', value: 'COMPLETED' },
          { label: 'NOT_STARTED', value: 'NOT_STARTED' },
        ],
      },
    ];

    return fields;
  }, [tenants, users]);

  const { page, perPage, sorting, filters, reactTableOptions } =
    useDataTableQueryState<TableRow>({
      filterFields,
    });

  const { data } = useQuery(searchAppointmentsQuery, {
    variables: {
      filter: {
        _and: [
          ...(filters.tenantId && filters.tenantId.length
            ? [
                {
                  tenantId: {
                    _in: filters.tenantId as string[],
                  },
                },
              ]
            : []),
          ...(filters.userId && filters.userId.length
            ? [
                {
                  userId: {
                    _in: filters.userId as string[],
                  },
                },
              ]
            : []),
          ...(filters.status && filters.status.length
            ? [
                {
                  status: {
                    _in: filters.status as AppointmentStatusEnum[],
                  },
                },
              ]
            : []),
        ],
      },
      offset: perPage * (page - 1),
      limit: perPage,
      orderBy:
        sorting && sorting.length
          ? sorting.map(({ desc, id }) => ({
              ...match<keyof TableRow, AppointmentsOrderBy>(id)
                .with('userId', () => ({
                  user: {
                    email: desc ? 'DESC' : 'ASC',
                  },
                }))
                .with('appointmentName', () => ({
                  name: desc ? 'DESC' : 'ASC',
                }))
                .with('tenantId', () => ({
                  tenant: {
                    name: desc ? 'DESC' : 'ASC',
                  },
                }))
                .otherwise(() => ({
                  [id]: desc ? 'DESC' : 'ASC',
                })),
            }))
          : null,
    },
  });

  const rowCount = data?.appointmentsAggregate?.aggregate?.count;

  const dataRows: TableRow[] =
    (data?.appointments ?? []).map((a) => {
      return {
        tenantId: a.tenant.id,
        tenantName: a.tenant.name,
        appointmentId: a.id,
        appointmentName: a.name,
        userId: a.user?.id ?? '',
        userEmail: a.user?.email ?? '',
        updatedAt: a.updatedAt,
        status: a.status!,
      };
    }) ?? [];

  const table = useReactTable({
    data: dataRows,
    columns,
    rowCount,
    ...reactTableOptions,
  });

  return (
    <div>
      <PageTitle>Appointments</PageTitle>
      <ServerDataTable<TableRow> table={table} filterFields={filterFields} />
    </div>
  );
};
