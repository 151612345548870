import { z } from 'zod';

import { MatchingRecord } from '@eluve/utils';

import {
  ExternalArtifactTypesEnum,
  LlmModelTypesEnum,
  LlmOutputTypesEnum,
} from './types';

/**
 * Provides a lookup object for the LlmOutputTypesEnum that can also
 * be used as a zod enum for runtime validation
 */
export const LlmOutputTypesLookup = {
  SOAP_NOTE: 'SOAP_NOTE', // At the moment, SOAP_NOTE represents any medical chart.
  CLIENT_RECAP: 'CLIENT_RECAP',
  FACT_VERIFICATION: 'FACT_VERIFICATION',
  BILLING_CODE_RECOMMENDATION: 'BILLING_CODE_RECOMMENDATION',
  DIFFERENTIAL_DIAGNOSES: 'DIFFERENTIAL_DIAGNOSES',
  GENERIC_OUTPUT: 'GENERIC_OUTPUT',
  REVIEW_OF_SYSTEMS: 'REVIEW_OF_SYSTEMS',
  PFSH: 'PFSH',
  FACT_GENERATION: 'FACT_GENERATION',
  CHIEF_COMPLAINT: 'CHIEF_COMPLAINT',
  PII_REMOVAL: 'PII_REMOVAL',
  DYNAMIC_OUTPUT: 'DYNAMIC_OUTPUT',
  TRANSLATION: 'TRANSLATION',
} as const satisfies MatchingRecord<LlmOutputTypesEnum>;

export const MedicalNotesLlmOutputTypes = [
  LlmOutputTypesLookup.DYNAMIC_OUTPUT,
  LlmOutputTypesLookup.SOAP_NOTE,
];

export const isLlmOutputMedicalNote = (
  outputType: LlmOutputTypesEnum | string = '',
) =>
  MedicalNotesLlmOutputTypes.map((type) => type.toString()).includes(
    outputType?.toString(),
  );

export const isLlmOutputDynamicOutput = (
  outputType?: LlmOutputTypesEnum | string | null,
) => LlmOutputTypesLookup.DYNAMIC_OUTPUT.toString() === outputType?.toString();

export const llmOutputTypesSchema = z.nativeEnum(LlmOutputTypesLookup);

export const LlmModelTypesLookup = {
  CLAUDE_2: 'CLAUDE_2',
  CLAUDE_3: 'CLAUDE_3',
  GPT_4: 'GPT_4',
  LLAMA_3: 'LLAMA_3',
  GPT_4_O: 'GPT_4_O',
} as const satisfies MatchingRecord<LlmModelTypesEnum>;

export const llmModelTypesSchema = z.nativeEnum(LlmModelTypesLookup);

export const ExternalArtifactTypesLookup = {
  INTAKE_FORM: 'INTAKE_FORM',
} as const satisfies MatchingRecord<ExternalArtifactTypesEnum>;

export const externalArtifactTypesSchema = z.nativeEnum(
  ExternalArtifactTypesLookup,
);
