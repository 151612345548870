import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import { AppointmentSummary } from './AppointmentSummary';
import { AppointmentTranscripts } from './AppointmentTranscripts';
import { ReviewAppointmentLayout } from './ReviewAppointmentLayout';
import { ReviewAppointmentsPage } from './ReviewAppointmentsPage';
import { ReviewDashGuard } from './ReviewDashGuard';
import { ReviewFeedbackPage } from './ReviewFeedbackPage';
import { ReviewLayout } from './ReviewLayout';

export const createReviewRoutes = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return [
    {
      path: 'review',
      element: (
        <ReviewDashGuard>
          <ApolloProvider client={client}>
            <Suspense>
              <ReviewLayout />
            </Suspense>
          </ApolloProvider>
        </ReviewDashGuard>
      ),
      children: [
        {
          index: true,
          element: <Navigate to="appointments" replace />,
        },
        {
          path: 'appointments',
          element: <ReviewAppointmentsPage />,
        },
        {
          path: 'appointments/:tenantId/:appointmentId',
          element: <ReviewAppointmentLayout />,
          children: [
            {
              index: true,
              element: <Navigate to="transcripts" replace />,
            },
            {
              path: 'transcripts',
              element: <AppointmentTranscripts />,
            },
            {
              path: 'summary',
              element: <AppointmentSummary />,
            },
          ],
        },
        {
          path: 'feedback',
          element: <ReviewFeedbackPage />,
        },
      ],
    },
  ];
};
