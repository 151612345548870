import { graphql } from '@eluve/graphql.tada';

export const searchAppointmentsQuery = graphql(`
  query searchAppointments(
    $filter: AppointmentsBoolExp
    $offset: Int
    $orderBy: [AppointmentsOrderBy!]
    $limit: Int
  ) {
    appointments(
      where: $filter
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      __typename
      id
      name
      status
      updatedAt
      user {
        __typename
        id
        email
      }
      tenant {
        __typename
        id
        name
      }
    }
    appointmentsAggregate(where: $filter) {
      __typename
      aggregate {
        __typename
        count
      }
    }
  }
`);

export const searchFeedbackQuery = graphql(`
  query searchFeedback(
    $filter: SummaryFeedbackBoolExp
    $offset: Int
    $orderBy: [SummaryFeedbackOrderBy!]
    $limit: Int
  ) {
    summaryFeedback(
      where: $filter
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      __typename
      id
      rating
      appointmentId
      tenantId
      type
      comment
      createdAt
      userId
      user {
        __typename
        id
        email
      }
      tenant {
        __typename
        id
        name
      }
      llm_output {
        __typename
        id
        modelPromptTemplate {
          __typename
          id
          model_args {
            __typename
            id
            name
          }
          template {
            __typename
            id
            name
          }
        }
      }
      summaryFeedbackTags {
        __typename
        feedbackTag {
          __typename
          id
          name
        }
      }
    }
    summaryFeedbackAggregate(where: $filter) {
      __typename
      aggregate {
        __typename
        count
      }
    }
  }
`);

export const reviewerTenantsFragment = graphql(`
  fragment ReviewerTenants on query_root {
    tenants {
      __typename
      id
      name
    }
  }
`);

export const reviewerUsersFragment = graphql(`
  fragment ReviwerUsers on query_root {
    users {
      __typename
      id
      email
    }
  }
`);

export const reviewerPromptTemplatesFragment = graphql(`
  fragment ReviewerPromptTemplates on query_root {
    promptTemplates {
      __typename
      id
      name
    }
  }
`);
