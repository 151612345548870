import React from 'react';
import { Outlet } from 'react-router-dom';

import { Box } from '@eluve/components';
import { TabNav } from '@eluve/smart-blocks';

export interface ReviewAppointmentLayoutProps {}

const route = [
  { name: 'Transcript', link: 'transcripts' },
  { name: 'Summary', link: 'summary' },
];

export const ReviewAppointmentLayout: React.FC<
  ReviewAppointmentLayoutProps
> = () => {
  return (
    <div>
      <Box>
        <TabNav tabs={route} />
      </Box>
      <Outlet />
    </div>
  );
};
