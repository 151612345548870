import { useSuspenseQuery } from '@apollo/client';
import { LogOut, UserRoundSearch } from 'lucide-react';
import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Outlet } from 'react-router-dom';

import { Box, Button } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { TabNav } from '@eluve/smart-blocks';

import {
  reviewerPromptTemplatesFragment,
  reviewerTenantsFragment,
  reviewerUsersFragment,
} from './operations';

export interface ReviewLayoutProps {}

const reviewerRoutes = [
  { name: 'Appointments', link: 'appointments' },
  { name: 'Feedback', link: 'feedback' },
];

const baseReviewerDataQuery = graphql(
  `
    query getBaseReviewerData {
      ...ReviewerTenants
      ...ReviwerUsers
      ...ReviewerPromptTemplates
    }
  `,
  [
    reviewerTenantsFragment,
    reviewerUsersFragment,
    reviewerPromptTemplatesFragment,
  ],
);

export const ReviewLayout: React.FC<ReviewLayoutProps> = () => {
  useSuspenseQuery(baseReviewerDataQuery);

  return (
    <>
      <Helmet>
        <title>Review | Eluve</title>
      </Helmet>
      <div className="min-h-full overflow-x-hidden">
        <div className="bg-brandGray800 pb-32">
          <header className="py-10">
            <div className="mx-auto w-full px-2 lg:px-8">
              <div className="flex flex-wrap items-center justify-between gap-y-2">
                <div className="flex items-center gap-2">
                  <UserRoundSearch className="h-10 w-10 text-yellow" />
                  <h1 className="text-3xl font-bold tracking-tight text-white">
                    Eluve Reviewer Tools
                  </h1>
                </div>
                <div className="flex flex-wrap gap-1 md:gap-2">
                  <Link to="/">
                    <Button size="xs">
                      Return to App <LogOut className="ml-2" />
                    </Button>
                  </Link>
                </div>
              </div>
              <Box className="mt-4">
                <TabNav tabs={reviewerRoutes} colorMode="dark" />
              </Box>
            </div>
          </header>
        </div>

        <main className="-mt-32">
          <div className="mx-auto w-full px-2 pb-12 lg:px-8">
            <div className="min-h-96 rounded-lg bg-white px-5 py-6 shadow sm:px-6">
              <div className="w-full">
                <Suspense fallback={'Loading...'}>
                  <Outlet />
                </Suspense>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};
