import clsx from 'clsx';
import React from 'react';
import { match } from 'ts-pattern';

import { tv } from '@eluve/components';
import {
  Badge,
  HStack,
  P,
  Tag,
  TagProps,
  cn,
  textStyles,
} from '@eluve/components';

import { CodeType } from './MedicalCodeTypeBadge';

const styles = tv({
  slots: {
    // TODO(jesse)[ELU-2647] - Fix border radius
    container: 'rounded-[8px] px-3 py-2',
    content: 'min-w-0 flex-1',
  },
  variants: {
    clickable: {
      true: {
        container: 'cursor-pointer hover:bg-brandGray50',
      },
    },
    status: {
      accepted: {
        container: 'bg-brandGray100',
        content: 'text-brandGray800',
      },
      recommendation: {
        container: ['bg-dashed-border-recommendation'],
        content: 'text-brandGray600',
      },
    },
  },
  defaultVariants: {},
});

const getTagColor = (codeType: CodeType) =>
  match<CodeType, TagProps['color']>(codeType)
    .with('CPT', () => 'teal')
    .with('ICD_10', () => 'purple')
    .with('ICD_9', () => 'orange')
    .with('SNOMED', () => 'default')
    .exhaustive();

export interface MedicalCodePropsOld {
  code: string;
  type: string;
}

export const MedicalCodeOld: React.FC<MedicalCodePropsOld> = ({
  code,
  type,
}) => {
  const typeLabel = type === 'ICD_10' ? 'ICD-10' : type;

  return (
    <>
      <Badge
        className={cn(
          'pointer-events-none',
          clsx({
            'bg-cyan-500': type === 'CPT',
            'bg-primary': typeLabel === 'ICD-10',
            'bg-gray-10': typeLabel !== 'CPT' && typeLabel !== 'ICD-10',
          }),
        )}
      >
        {type}
      </Badge>
      <P>{code}</P>
    </>
  );
};

type MedicalCodeProps = {
  codeType: CodeType;
  code: string;
  description: string;
  isRecommended?: boolean;
  includeDescription?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  onClick?: () => void;
};

export const MedicalCodeTag = React.forwardRef<
  HTMLDivElement,
  { code: string; codeType: CodeType }
>(({ code, codeType }, ref) => (
  <Tag label={code} color={getTagColor(codeType)} ref={ref} />
));

export const MedicalCode = React.forwardRef<HTMLDivElement, MedicalCodeProps>(
  (
    {
      codeType,
      code,
      description,
      isRecommended = false,
      includeDescription = true,
      onClick,
      startAdornment,
      endAdornment,
    },
    ref,
  ) => {
    const { container, content } = styles({
      status: isRecommended ? 'recommendation' : 'accepted',
      clickable: Boolean(onClick),
    });

    return (
      <HStack
        className={container()}
        wFull={false}
        inline
        ref={ref}
        onClick={onClick}
        gap={3}
      >
        {startAdornment && startAdornment}

        <HStack gap={2}>
          <Tag label={code} size="xs" color={getTagColor(codeType)} />

          <div className={textStyles.body({ size: 's', className: content() })}>
            <p>{`${includeDescription ? `${description}` : ''}`}</p>
          </div>
        </HStack>

        {endAdornment && endAdornment}
      </HStack>
    );
  },
);

MedicalCode.displayName = 'MedicalCode';
