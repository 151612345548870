import { useMutation } from '@apollo/client';

import { useApiClient } from '@eluve/api-client-provider';
import { cacheUtils } from '@eluve/apollo-client';
import { graphql } from '@eluve/graphql.tada';
import { useSession } from '@eluve/session-helpers';
import { HumanFriendlyError } from '@eluve/vendor';

import { useAppointmentContext } from './appointment.context';
import {
  appointmentDoctorInteractionFragment,
  doctorInteractionFragment,
  externalChartFragment,
  externalChartsFragment,
} from './operations';
import { useAppointmentPatient } from './useAppointmentPatient';
import { useExternalAppointmentId } from './useExternalAppointmentId';
import { SyncToEhrResponse } from './useSyncToEhr';

type SyncNoteToEhrResponse = SyncToEhrResponse & {
  humanFriendlyError?: HumanFriendlyError;
};

export const upsertExternalChartMutation = graphql(
  `
    mutation createExternalChart(
      $appointmentId: uuid!
      $chartUrl: String!
      $externalAppointmentId: String
      $patientId: uuid!
      $externalPatientId: String!
      $externalEhrId: uuid!
      $externalChartId: String!
    ) {
      insertExternalChartsOne(
        object: {
          appointmentId: $appointmentId
          chartUrl: $chartUrl
          externalPatientId: $externalPatientId
          patientId: $patientId
          externalAppointmentId: $externalAppointmentId
          externalEhrId: $externalEhrId
          externalChartId: $externalChartId
        }
        onConflict: {
          constraint: unique_external_charts_external_chart_id
          updateColumns: []
        }
      ) {
        ...externalChart
      }
    }
  `,
  [externalChartFragment],
);

export const markAppointmentAsSignedMutation = graphql(
  `
    mutation insertDoctorInteractionSignedNote($appointmentId: uuid!) {
      insertAppointmentDoctorInteractionsOne(
        object: { appointmentId: $appointmentId, noteSignedAt: NOW }
        onConflict: {
          constraint: appointment_doctor_interactions_pkey
          updateColumns: [noteSignedAt]
        }
      ) {
        ...doctorInteraction
      }
    }
  `,
  [doctorInteractionFragment],
);

export const useSignNoteOnEluve = () => {
  const { tenantId, appointmentId } = useAppointmentContext();
  const apiClient = useApiClient();
  const patient = useAppointmentPatient();
  const appointmentExternalId = useExternalAppointmentId();

  const { firstName: userFirstName, lastName: userLastName } = useSession();

  const externalPatientInfo = patient?.external_patients_info?.[0];
  const externalEhr = externalPatientInfo?.external_ehr;

  const patientExternalId = externalPatientInfo?.externalPatientId;

  const upsertExternalChart = async (data: {
    appointmentId: string;
    chartUrl: string;
    externalChartId: string;
    externalAppointmentId: string | null;
    patientId: string;
    externalPatientId: string;
    externalEhrId: string;
  }) => {
    const {
      chartUrl,
      externalChartId,
      externalAppointmentId,
      patientId,
      externalPatientId,
      externalEhrId,
    } = data;
    await apiClient.charts.createChart({
      body: {
        appointmentId,
        chartUrl,
        externalChartId,
        externalAppointmentId,
        patientId,
        externalPatientId,
        externalEhrId,
      },
      params: {
        tenantId,
      },
    });

    cacheUtils.updateFragment(
      {
        fragment: externalChartsFragment,
        key: { id: appointmentId },
      },
      (existingData) => {
        if (!appointmentId) {
          return existingData;
        }

        return {
          id: appointmentId,
          __typename: 'Appointments' as const,
          external_charts: [
            {
              __typename: 'ExternalCharts' as const,
              id: '-1',
              appointmentId,
              externalEhrId,
              chartUrl,
              signedAt: null,
              externalChartId,
              patientId,
              externalPatientId,
              externalAppointmentId,
            },
          ],
        };
      },
    );
  };

  const [markAppointmentAsSigned] = useMutation(
    markAppointmentAsSignedMutation,
    {
      optimisticResponse: () => ({
        insertAppointmentDoctorInteractionsOne: {
          __typename: 'AppointmentDoctorInteractions' as const,
          appointmentId,
          externalChartUrl: null,
          additionalNotes: '',
          noteSignedAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          user: {
            __typename: 'Users' as const,
            id: '-1',
            firstName: userFirstName,
            lastName: userLastName,
          },
        },
      }),
      update(_, { data }) {
        cacheUtils.updateFragment(
          {
            fragment: appointmentDoctorInteractionFragment,
            key: { id: appointmentId },
          },
          (existingData) => {
            if (!data?.insertAppointmentDoctorInteractionsOne) {
              return existingData;
            }

            return {
              id: data.insertAppointmentDoctorInteractionsOne.appointmentId,
              __typename: 'Appointments' as const,
              doctor_interaction: {
                ...existingData?.doctor_interaction,
                ...data.insertAppointmentDoctorInteractionsOne,
              },
            };
          },
        );
      },
    },
  );

  return async (data?: { chartUrl: string; chartId: string }) => {
    if (data) {
      const { chartUrl, chartId } = data;
      if (
        chartUrl &&
        chartId &&
        externalEhr &&
        patientExternalId &&
        patient?.id
      ) {
        await upsertExternalChart({
          appointmentId,
          chartUrl,
          externalChartId: chartId,
          externalAppointmentId: appointmentExternalId ?? null,
          patientId: patient?.id,
          externalPatientId: patientExternalId,
          externalEhrId: externalEhr.id,
        });
      }
    }
    const { errors } = await markAppointmentAsSigned({
      variables: {
        appointmentId,
      },
    });
    if (!errors) {
      return true;
    } else {
      return false;
    }
  };
};
