import { appointmentArtifactsFragment } from '.';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useCompleteFragment } from '@eluve/apollo-client';
import { TranscriptText } from '@eluve/blocks';
import { Box, Divider, H4, HStack, Label, VStack } from '@eluve/components';
import { Translator } from '@eluve/translation';
import { toSentences } from '@eluve/utils';

export const TranscriptContent: React.FC = () => {
  const { appointmentId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };
  const appointment = useCompleteFragment({
    fragment: appointmentArtifactsFragment,
    key: { id: appointmentId },
  });
  const [translatedContent, setTranslatedContent] = useState<string | null>(
    null,
  );

  const transcriptText = appointment?.transcription?.transcript ?? '';
  const transcriptWordCount = transcriptText.split(/\s+/).length;
  const text = toSentences(appointment?.transcription?.transcript ?? '');
  return (
    <VStack>
      <Label>Word count: {transcriptWordCount}</Label>
      <Box className="w-1/6">
        <Label>Need translation?</Label>
        <Translator
          originalContent={text}
          setTranslatedContent={setTranslatedContent}
        />
      </Box>

      <HStack align="start">
        <Box className="mt-6 rounded-lg border p-4">
          <H4>Transcript</H4>
          <Divider />
          <TranscriptText>{text}</TranscriptText>
        </Box>
        {translatedContent && (
          <Box className="mt-6 rounded-lg border p-4">
            <H4>Transcript (translated)</H4>
            <Divider />
            <TranscriptText>{translatedContent}</TranscriptText>
          </Box>
        )}
      </HStack>
    </VStack>
  );
};
